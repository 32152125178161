<template>
  <b-modal
    id="create-modal"
    :title="$t('my-nectios.welcome.start-creating')"
    :ok-title="$t('form.actions.save')"
    ok-only
    :ok-disabled="createForm.name === ''"
    @ok="handleCreate()"
  >
    <label class="font-weight-bold">{{ $t('my-nectios.welcome.create-title') }}</label>
    <v-select
      id="type"
      v-model="createForm.type"
      class="mb-1 text-capitalize"
      :options="typesSelect"
      :get-option-label="(option) => `${option.name}`"
      :searchable="false"
      :placeholder="$t('form.select-placeholder')"
      label="name"
      :clearable="false"
    />
    <label class="font-weight-bold">
      {{ $t('my-nectios.welcome.create-name', {type: createForm.type.name}) }}
    </label>
    <b-input v-model="createForm.name" :placeholder="$t('form.type-placeholder')" @keydown.enter.prevent="handleCreate()" />
  </b-modal>
</template>

<script>
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { MainType } from "@copernicsw/community-common";
import Service from '@/config/service-identifiers';

export default {
  name: 'Nectios',
  components: { vSelect },
  mixins: [ToastNotificationsMixin, GridStatusMixin],

  data() {
    return {
      createForm: {
        type: {},
        name: '',
        isSmall: false,
      },
    };
  },
  computed: {
    typesSelect() {
      return [
        { 
          type: 'Community', 
          name: `${this.$t('my-nectios.welcome.types-options.community')}`, 
          description: this.$t('my-nectios.welcome.types-options.descriptions.community') 
        },
        { 
          type: 'Event', 
          name: `${this.$t('my-nectios.welcome.types-options.event')}`, 
          description: this.$t('my-nectios.welcome.types-options.descriptions.event')
        },
        // { type: 'Workspace', name: 'Workspace', description: '' },
        // { type: 'Course', name: this.$t('my-nectios.welcome.types-options.course') },
        // { type: 'Project', name: this.$t('my-nectios.welcome.types-options.project') },
      ];
    },
    currentUser() {
      return this.$store.getters.loggedUser;
    },
  },
  created() {
    this.createForm.type = this.typesSelect[0];
  },
  methods: {
    async handleCreate() {
      const objectKey = `is${this.createForm.type.type}`;
      const value = 1;
      const type = {};
      type[objectKey] = value;
      try {
        // await this.$store.dispatch('createCommunity', { type, name: this.createForm.name });
        const response = await this.$store.$service[Service.BackendClient].post(
          "communities",
          {
            name: this.createForm.name,
            mainTypeID: MainType[this.createForm.type.type],
          },
        );
        if (this.currentUser.appSumoID === null) {
          this.$router.replace({
            name: "my-plans",
            params: { community: response.data.slug },
          });
        } else {
          window.location = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', response.data.slug).replace(
            '{subdomain}',
            'app',
          )}/backoffice/apps`;
        }
        this.$router.replace({
          name: "my-plans",
          params: { community: response.data.slug },
        });
        this.notifySuccess(this.$t('my-nectios.welcome.messages.success-creation', { type: this.createForm.type.name }));
      } catch (e) {
        if (e.response?.data?.maxCommunitiesYouCanCreate && this.currentUser.appSumoID != null) {
          this.$emit('max-communities', true);
        } else {
          this.notifyError(this.$t('my-nectios.welcome.messages.error-creation', { type: this.createForm.type.name }));
        }
      }
      this.createForm.name = '';
    },
  },
};
</script>
