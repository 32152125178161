<template>
  <main-container>
    <div v-if="loggedUser && loggedUser.key && loggedUser.hasPassword" class="mt-lg-n5">
      <!-- Section presentation and create space button -->
      <b-row v-if="isNectiosUser" class="mx-50 flex-wrap flex-lg-nowrap mb-2">
        <b-col cols="12" lg="8" :style="bannerStyle" class="pb-2">
          <h2 class="text-center text-white mt-2 mt-xl-5">
            {{ $t("my-nectios.banner.title") }}
          </h2>
          <div class="mx-5 px-25">
            <h4 class="text-center text-white mx-xl-5 px-lg-5">
              {{ $t("my-nectios.banner.subtitle") }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="mt-1 mt-lg-0 p-0">
          <b-card class="mb-0 border-0" :style="callStyle">
            <h2 class="text-center mt-2 mt-xl-5">
              {{ $t("my-nectios.banner.call-to-action") }}
            </h2>
            <div class="d-flex flex-wrap justify-content-center mt-1 mb-1">
              <b-button
                v-b-modal.create-modal
                variant="primary"
                type="submit"
                class="d-lg-none mt-1 mb-1 mb-lg-0 font-weight-bolder button-text"
                block
              >
                {{ $t("my-nectios.banner.call") }}
              </b-button>
              <b-button
                v-b-modal.create-modal
                variant="primary"
                type="submit"
                block
                class="d-none d-lg-block ml-1 font-weight-bolder button-text"
                style="max-width: 250px"
              >
                {{ $t("my-nectios.banner.call") }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- My Spaces section -->
      <b-card>
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <div class="d-flex flex-wrap">
            <h4>{{ $t("my-nectios.my-communities") }}</h4>
            <b-badge v-if="total > 0" variant="primary" class="align-self-start ml-50">
              {{ total }}
            </b-badge>
          </div>
          <!-- Search -->
          <b-form-input
            v-model="searchInput"
            type="text"
            class="d-lg-none my-1 mb-lg-3"
            :placeholder="$t('form-create-item.search-input')"
            @input="search"
          />
          <b-form-input
            v-model="searchInput"
            type="text"
            class="d-none d-lg-block mx-auto mb-1 mb-lg-3"
            style="width: 600px"
            :placeholder="$t('form-create-item.search-input')"
            @input="search"
          />
          <!-- Space type filter -->
          <v-select
            id="type"
            v-model="createForm.type"
            class="mb-1 text-capitalize d-lg-none w-100"
            :options="typesSelect"
            :get-option-label="(option) => option.name"
            :searchable="false"
            :placeholder="$t('form.select-placeholder')"
            label="name"
            style="width: 200px"
            :clearable="false"
            @input="fetchData(1)"
          />
          <div class="d-flex flex-wrap">
            <v-select
              id="type"
              v-model="createForm.type"
              class="mb-1 text-capitalize d-none d-lg-block"
              :options="typesSelect"
              :get-option-label="(option) => option.name"
              :searchable="false"
              :placeholder="$t('form.select-placeholder')"
              label="name"
              style="width: 200px"
              :clearable="false"
              @input="fetchData(1)"
            />
          </div>
        </div>
        <!-- loading -->
        <div v-if="isLoading" class="mb-5">
          <b-spinner
            type="grow"
            small
            variant="primary"
            class="mx-auto mt-3 d-block"
          />
        </div>
        <!-- Spaces cards grid -->
        <div v-else>
          <div v-if="communities && communities.length > 0" class="mt-2 d-flex flex-wrap justify-content-around w-100">
            <div v-for="(collective, index) in communities"
              :key="index"
              class="x-scroll-element mx-1 mb-2">
              <b-link
                class="text-dark"
                target="_blank"
                :href="getCollectiveUrl(collective)"
              >
                <b-card class="small-card">
                  <card-thumbnail
                    :src="thumbnailSrc(collective)"
                    :placeholder="placeholder"
                  />
                  <h5 class="mt-1">
                    {{ titleShort(collective) }}
                  </h5>
                  <p
                    class="m-0 small-card-headline text-secondary html-text-ellipsis-2"
                  >
                    {{ headline(collective) }}
                  </p>
                  <small v-if="collective && collective.mainType">
                    <b-badge pill
                      :variant="getColor(collective.mainType)"
                      class="ml-25">
                      {{ collective.mainType }}
                    </b-badge>
                  </small>
                </b-card>
              </b-link>
            </div>
          </div>
          <!-- Container without data: Placeholder -->
          <b-row v-else class="horizontal-placeholder">
            <b-col v-if="placeholder" cols="12">
              <img :src="placeholder" />
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{
                  $t("available.message", {
                    itemName: $t("my-nectios.communities"),
                  })
                }}
              </p>
            </b-col>
          </b-row>
          <div v-if="isLoadingNextPage" class="mb-5">
            <b-spinner
              type="grow"
              small
              variant="primary"
              class="mx-auto mt-3 d-block"
            />
          </div>
          <b-button
            v-if="hasMore"
            class="center-x my-3"
            variant="outline-primary"
            @click="getMoreCommunities"
          >
            {{ $t("action.load-more") }}
          </b-button>
        </div>
      </b-card>
      <create-space-modal @max-communities="handleCreateMax()" />
      <error-modal
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        :title="$t('appsumo.errors-modal.title')"
        :subtitle="$t('appsumo.errors-modal.subtitle')"
      />
    </div>
    <div v-else-if="loggedUser && loggedUser.key && !loggedUser.hasPassword">
      <b-card
        no-body
        class="w-100 h-100 mt-lg-n5 password-card d-flex align-items-center justify-content-center overflow-hidden"
      >
        <div
          class="w-100 position-absolute h-100 d-flex justify-content-center align-items-center"
        >
          <img :src="passwordImage" alt="" class="password-image h-100 w-auto" />
        </div>
        <b-row class="p-2 mt-5 h-100 d-flex flex-column align-items-center">
          <b-col cols="12">
            <h2 class="font-large-3 mb-1 text-primary mt-50">
              Welcome to My Nectios
            </h2>

            <h2 class="font-medium-4 mb-3 pl-50">
              First things first, time to create your password.
            </h2>
          </b-col>
          <b-col cols="12">
            <b-form class="mt-1 px-50" @submit.prevent="createPassword">
              <!-- password -->
              <b-form-group>
                <label for="sign-up-password">{{ $t("sign-up.form.password.label") }}</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="sign-up-password"
                    v-model="password"
                    class="form-control-merge"
                    type="password"
                    name="sign-up-password"
                    :placeholder="$t('sign-up.form.password.placeholder')"
                    :label="$t('sign-up.form.password.label')"
                    required
                    aria-describedby="passwordHelp"
                  />
                </b-input-group>
                <small
                  v-if="password.length < 6 && password.length > 0"
                  id="passwordHelp"
                  class="form-text text-danger"
                >
                  {{ $t("sign-up.form.password.min-characters") }}
                </small>
              </b-form-group>
              <!-- Repeat password -->
              <b-form-group>
                <label for="sign-up-password2">{{ $t("sign-up.form.repeat-password.label") }}</label>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="sign-up-password2"
                    v-model="password2"
                    class="form-control-merge"
                    type="password"
                    name="sign-up-password2"
                    required
                    :placeholder="$t('sign-up.form.repeat-password.placeholder')"
                    :label="$t('sign-up.form.repeat-password.label')"
                  />
                </b-input-group>
                <small
                  v-if="password2.length < 6 && password2.length > 0"
                  id="passwordHelp"
                  class="form-text text-danger"
                  >{{ $t("sign-up.form.password.min-characters") }}</small
                >
              </b-form-group>

              <!-- Error Toast -->
              <div class="error-toast mb-1">
                <b-alert
                  v-if="isWrongPassword"
                  show
                  variant="danger"
                  class="error-notification m-0"
                >
                  {{ $t("sign-up.error-message.password") }}
                </b-alert>
                <b-alert
                  v-if="invalidCredentials"
                  show
                  variant="danger"
                  class="m-0 error-notification"
                >
                  {{ $t("sign-up.error-message.invalid-credentials") }}
                </b-alert>
                <b-alert
                  v-if="generalError"
                  show
                  variant="danger"
                  class="m-0 error-notification"
                >
                  {{ $t("error-message.general-error") }}
                </b-alert>
              </div>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                :disabled="password.length < 6 || password2.length < 6"
                block
              >
                {{ $t("sign-up.form.action.sign-in") }}
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-else>
      <b-spinner large variant="primary" class="mx-auto mt-3 d-block" />
    </div>
  </main-container>
</template>

<script>
import MainContainer from "@/my-nectios/components/MainContainer.vue";
import ListContainer from "@core/constants/ListContainer";
import CommunitiesPlaceholder from "@/assets/images/placeholders/light/communities.svg";
import Service from "@/config/service-identifiers";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CardThumbnail from "@core/components/card-thumbnail/CardThumbnail.vue";
import vSelect from "vue-select";
import { MainType } from "@copernicsw/community-common";
import Banner from "@/assets/images/placeholders/light/Header_My_Left.svg";
import callToAction from "@/assets/images/placeholders/light/Header_My_Right.svg";
import CreatePasswordWeb from "@/assets/images/pages/crear_contrasena_large.svg";
import ErrorModal from "@core/components/modal/ErrorModal.vue";
import CreateSpaceModal from "../components/CreateSpaceModal.vue";
import Ripple from 'vue-ripple-directive';

export default {
  name: "MySpaces",
  components: {
    CardThumbnail,
    CreateSpaceModal,
    ErrorModal,
    vSelect,
    MainContainer,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      createForm: {
        type: {},
        name: "",
      },
      isContactModalVisible: false,
      isLoading: false,
      isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      communities: [],
      currentPage: 1,
      total: null,
      searchInput: "",
      page: 1,
      timeoutID: null,
      isSmall: false,
      password: "",
      password2: "",
      isWrongPassword: false,
      generalError: false,
      invalidCredentials: false,
      isNectiosUser: false
    };
  },
  computed: {
    callToActionImage() {
      return callToAction;
    },
    style() {
      return `background: url('${this.passwordImage}'); background-size: cover`;
    },
    passwordImage() {
      return CreatePasswordWeb;
    },
    typesSelect() {
      return [
        { 
          type: "all", 
          name: this.$t("display-type-options.show-all") 
        },
        {
          type: "Community",
          name: this.$t("my-nectios.welcome.types-options.community"),
        },
        {
          type: "Event",
          name: this.$t("my-nectios.welcome.types-options.event"),
        },
        { 
          type: "Workspace", 
          name: "Workspace" 
        },
        // { type: 'Course', name: this.$t('my-nectios.welcome.types-options.course') },
        // { type: 'Project', name: this.$t('my-nectios.welcome.types-options.project') },
      ];
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    currentUser() {
      return this.$store.getters.loggedUser.key;
    },
    banner() {
      return Banner;
    },
    bannerStyle() {
      return {
        "background-image": `url(${this.banner})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        ...(this.isSmall
          ? { "background-position": "center" }
          : { "background-position": "center" }),
        "border-radius": "1.2rem",
        "margin-right": "10px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
      };
    },
    callStyle() {
      return {
        "background-image": `url(${this.callToActionImage})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "border-radius": "1.2rem",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
      };
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
  },
  watch: {
    async currentUser() {
      if (this.searchInput) {
        return;
      }
      await this.fetchData(this.page);
    },
    loggedUser(value) {
      return value;
    },
    communities(value) {
      return value;
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.createForm.type = this.typesSelect[0];
    await this.fetchData(this.page);
    if(this.loggedUser?.email.includes('@nectios.com')){
      console.log('Welcome, you are part of the Nectios Staff. Superuser power activated!')
      this.isNectiosUser = true;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setLocale(language) {
      this.$store.dispatch("setLocale", language);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    titleShort(collective) {
      const title = translateTranslationTable(
        this.$store.state.locale.currentLocale,
        collective?.name
      );
      if (title.length < 19) {
        return title;
      }
      return this.trimString(title);
    },
    headline(collective) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        collective?.headline
      );
    },
    getCollectiveUrl(collective) {
      return process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        collective?.subdomain || "app"
      ).replace("{slug}", collective?.slug);
    },
    trimString(string) {
      const trimmedString = string.substr(0, 19);
      return `${trimmedString}...`;
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (this.page !== page) {
        this.isLoadingNextPage = true;
      }
      this.page = page;
      const response = await this.$store.$service[Service.BackendClient].get(
        "my/spaces",
        {
          params: {
            page: this.page,
            userKey: this.currentUser,
            ...(this.createForm.type.type !== "all"
              ? { mainTypeID: MainType[this.createForm.type.type] }
              : ""),
          },
        }
      );
      this.communities =
        this.page === 1
          ? response.data.data.map((item) => item)
          : [...this.communities, ...response.data.data.map((item) => item)];
      this.hasMore =
        response.data.meta.current_page < response.data.meta.last_page;
      this.total = response.data.meta.total;
      if (this.loggedUser?.language) {
        this.setLocale(this.loggedUser?.language);
      }
      this.isLoading = false;
      this.isLoadingNextPage = false;
    },
    async handleCreate() {
      const objectKey = "isCommunity";
      const value = 1;
      const type = {};
      type[objectKey] = value;
      try {
        await this.$store.dispatch("createCommunity", {
          type,
          name: this.createForm.name,
        });
        this.notifySuccess(
          this.$t("my-nectios.welcome.messages.success-creation", {
            type: this.createForm.type.name,
          })
        );
      } catch {
        this.notifyError(
          this.$t("my-nectios.welcome.messages.error-creation", {
            type: this.createForm.type.name,
          })
        );
      }
      this.createForm.name = "";
    },
    translateTranslationTable,
    thumbnailSrc(collective) {
      return collective?.logoURL ?? collective?.bgURL ?? CommunitiesPlaceholder;
    },
    async getMoreCommunities() {
      this.lastLoadedPage += 1;
      return this.fetchData(this.lastLoadedPage);
    },
    async search() {
      let response = "";
      if (this.searchInput.length === 0) {
        this.communities.length = 0;
        await this.fetchData(this.page);
      } else {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
        }
        this.timeoutID = setTimeout(async () => {
          response = await this.$store.$service[Service.BackendClient].get(
            "my/spaces",
            {
              params: {
                searchString: this.searchInput.toLowerCase().trim(),
                userKey: this.currentUser,
                mainTypeID: MainType[this.createForm.type.type],
              },
            }
          );
          this.timeoutID = null;
          this.communities.length = 0;
          this.page = 1;
          this.communities = [...response.data.data.map((item) => item)];
          this.hasMore =
            response.data.meta.current_page < response.data.meta.last_page;
          this.isLoading = false;
        }, 500);
      }
    },
    getColor(type) {
      const types = {
        Event: "success",
        Community: "primary",
        Project: "info",
        Challenge: "warning",
        Workspace: "dark",
        Service: "light",
        Subcommunity: "secondary",
      };
      return types[type] || "secondary";
    },
    async createPassword() {
      this.isWrongPassword = false;

      if (this.password === this.password2) {
        try {
          const response = await this.$store.dispatch("createItem", {
            noSet: true,
            item: {
              itemType: "/createPassword",
              requestConfig: {
                password: this.password,
                repeatPassword: this.password2,
                userKey: this.currentUser,
              },
            },
          });
          if (response.status === 200) {
            this.loggedUser.hasPassword = true;
          } else if (
            response.status === 403 ||
            response.status === 400 ||
            response.status === 409
          ) {
            this.invalidCredentials = true;
          } else {
            this.generalError = true;
          }
        } catch (error) {
          if (
            error.response.status === 403 ||
            error.response.status === 400 ||
            error.response.status === 409
          ) {
            this.invalidCredentials = true;
          } else {
            this.generalError = true;
          }
        }
      } else {
        this.isWrongPassword = true;
      }

      return "";
    },
    handleCreateMax() {
      this.$bvModal.show("error-modal");
    },
  },
};
</script>
<style scoped>
.button-text {
  font-size: 18px !important;
}
.password-card {
  min-height: 83vh;
  background-color: hsl(187, 47%, 93%);
}
.password-image {
  min-height: 83vh;
}
</style>
